import React from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Features from '../components/Features';
import About from '../components/About';
import Services from '../components/Services';
import Portfolio from '../components/Portfolio';
import Contact from '../components/Contact';
import VisaServices from '../components/VisaServices';
import Testimonial from '../components/Testimonial';
import TestimonialHeading from '../components/TestimonialHeading';

import Blog from '../components/Blog';


/**====== CSS Files Start ======**/
import '../assets/css/animate.css';
import '../assets/css/bootstrap-5.0.0-beta1.min.css';
// import '../assets/css/bootstrap-5.0.5-alpha.min.css';
import '../assets/css/default.css';
import '../assets/css/LineIcons.2.0.css';
import '../assets/css/style.css';
import '../assets/css/style.css.map';
// import '../assets/css/tiny-slider.css';
/**====== CSS Files ENDS ======**/

function RootLayout() {
    return (
        <div>
            
            <Header />
            {/* <Preloader /> */}
            <Features />
            <About />
            <Services />
            <VisaServices />
            <Portfolio />
            {/* <Pricing /> */}
            {/* <Team /> */}
            <TestimonialHeading />
            <Testimonial />
            <Blog />
            <Contact />

            <Footer />
        </div>
    );
}

export default RootLayout;
