import React from 'react';

import portfolioImage1 from '../assets/images/portfolio-1.jpg';
import portfolioImage2 from '../assets/images/portfolio-2.jpg';
import portfolioImage4 from '../assets/images/portfolio-4.jpg';
import portfolioImage5 from '../assets/images/portfolio-5.jpg';
import portfolioImage6 from '../assets/images/portfolio-6.jpg';
import portfolioImage8 from '../assets/images/portfolio-8.jpg';
import portfolioImage10 from '../assets/images/portfolio-10.jpg';
import portfolioImage11 from '../assets/images/portfolio-11.jpg';

function Portfolio() {
    return (
        <section id="portfolio" className="portfolio_area pt-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section_title text-center pb-60">
                            <h4 className="title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">Our Workplace</h4>
                            {/* <p className="wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.4s">Lorem ipsum dolor sit
                                amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt labor dolore.</p> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="portfolio_wrapper d-flex flex-wrap portfolio-image-div">
                
                <div className="single_portfolio wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.3s">
                    <img src={portfolioImage2} alt="portfolio" className="portfolio-img-div" />
                </div>
                
                <div className="single_portfolio wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">
                    <img src={portfolioImage1} alt="portfolio" className="portfolio-img-div" />
                </div>
                
                <div className="single_portfolio wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">
                    <img src={portfolioImage10} alt="portfolio" className="portfolio-img-div" />
                </div>

                <div className="single_portfolio wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">
                    <img src={portfolioImage11} alt="portfolio" className="portfolio-img-div" />
                </div>
                {/* 2nd row */}

                <div className="single_portfolio wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.5s">
                    <img src={portfolioImage4} alt="portfolio" className="portfolio-img-div" />
                </div>
                
                <div className="single_portfolio wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.4s">
                    <img src={portfolioImage8} alt="portfolio" className="portfolio-img-div" />
                </div>

                <div className="single_portfolio wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.3s">
                    <img src={portfolioImage6} alt="portfolio" className="portfolio-img-div" />
                </div>
                
                <div className="single_portfolio wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.4s">
                    <img src={portfolioImage5} alt="portfolio" className="portfolio-img-div" />
                </div>
            </div>
        </section>
    );
}

export default Portfolio;
