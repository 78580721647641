import React from 'react';

function About() {

    const bgImg = {
        backgroundImage: `url(${require('./../assets/images/about.jpg')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      };

    return (
        <section id="about" className="pt-130">
            <div className="about_area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about_content pt-120 pb-130">
                                <div className="section_title pb">
                                    <h4 className="title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">About Our
                                        Creative Proccess</h4>
                                    <p className="wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.4s">We have great experience regarding overseas study, colleges/universities, courses and visa filling.
                                        We have direct tie-up with some colleges and universities which helps to make your visa process
                                        easy.
                                    </p>
                                    <p className="wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.6s">We provide all services to our client like Show Money, Finance/Loan and Appointment Book etc.We deal in transparency.
                                        We charge very less as compared to market.
                                        No hidden charges,
                                        Our impact is speak louder than our word.</p>
                                </div>
                                <a href="#0" className="main-btn wow fadeInUp" data-wow-duration="1.3s"
                                    data-wow-delay="0.7s">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about_image bg_cover wow fadeInLeft" data-wow-duration="1.3s" data-wow-delay="0.2s"
                    style={bgImg}>
                    <div className="image_content">
                        <h4 className="experience"><span>5</span> Years of Experience</h4>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default About;
