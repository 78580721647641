import React from 'react';

function Features() {
    return (
        <section id="features" className="features_area pt-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section_title text-center pb-25">
                            <h4 className="title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">Why Choose Us</h4>
                            <p className="wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.4s">White shade study abroad consultant was established with a vision to help students further their dream, by opening a world of opportunities to them.</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-7">
                        <div className="single_features text-center mt-30 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.2s">
                            <i className="lni lni-layers"></i>
                            <h4 className="features_title">Accurate Guidance</h4>
                            <p>At White Shade Visa Consultancy, we help every individual in making adequate decision for
                                their overseas plans.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-7">
                        <div className="single_features text-center mt-30 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.4s">
                            <i className="lni lni-layout"></i>
                            <h4 className="features_title">Our Presence</h4>
                            <p>Our presence is designed to take you beyond the nation.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-7">
                        <div className="single_features text-center mt-30 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.6s">
                            <i className="lni lni-bolt"></i>
                            <h4 className="features_title">Great Support</h4>
                            <p>Since our founding, our primary goal has been to provide satisfactory immigration services to our
                                clients. We help and guide our customer on every step. We provide 24*7 customer services.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default Features;
