import React from 'react';

function Footer() {
    return (
        <footer id="footer" className="footer_area">
            <div className="container">
                <div className="footer_wrapper text-center d-lg-flex align-items-center justify-content-between">
                    <p className="credit">White Shade Immigration & Consultancy Services</p>
                    <div className="footer_social pt-15">
                        <ul>
                            <li><a href="https://www.facebook.com/people/White-Shade-Visa-Consultancy/61556533627229/?mibextid=LQQJ4d"><i className="lni lni-facebook-original"></i></a></li>
                            <li><a href="https://www.instagram.com/white_shade_visa_consultancy/?igsh=MWVqanllNmQ2cTI5aQ%3D%3D&utm_source=qr"><i className="lni lni-instagram-original"></i></a></li>
                            <li><a href="https://youtube.com/@whiteshadevisaconsultancy?si=1E4O5SleYLcDPqyj"><i className="lni lni-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
