import React from 'react';

function Services() {
    return (
        <section id="services" className="features_area pt-120 pb-100">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section_title text-center   ">
                            <h4 className="title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">Our Serviceability</h4>
                            {/* <p className="wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.4s">Lorem ipsum dolor sit
                                amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt labor dolore.</p> */}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div className="single_features text-center mt-60 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.2s">
                            <i className="lni lni-diamond-alt"></i>
                            <h4 className="features_title">Visa counselling</h4>
                            <p>Our Visa Counsellors highly educated & well trained in overseas filed. We have deep knowledge
                                about colleges, universities and courses it helps you to choose appropriate path.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="single_features text-center mt-60 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.3s">
                            <i className="lni  lni-world"></i>
                            <h4 className="features_title">Immigration</h4>
                            <p>Navigate your journey with us – simplifying immigration processes for a seamless transition. Your
                                new beginning starts here.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="single_features text-center mt-60 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.4s">
                            <i className="lni  lni-laptop"></i>
                            <h4 className="features_title">Visa Filing</h4>
                            <p>White Shade Visa Consultancy is one of the few visa consultancy services that promise to
                                change things for students by offering them precise help.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="single_features text-center mt-60 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.2s">
                            <i className="lni  lni-plane"></i>
                            <h4 className="features_title">Air Tickets</h4>
                            <p>We offer fair international and domestic Ticketing. Since this is the later stop towards your
                                journey of success.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
