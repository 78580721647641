import React from 'react';

import logo from './../assets/images/wslogo-2.png';

function Header() {

    return (
        <section id="home" className="header_area">
            <div id="header_navbar" className="header_navbar">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand" href="index.html">
                                    <img id="logo" alt="logo" src={logo} />
                                </a>
                                <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                    <ul id="nav" className="navbar-nav ms-auto">
                                        <li className="nav-item">
                                            <a className="page-scroll active" href="#home">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll" href="#about">About</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll" href="#services">Services</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll" href="#portfolio">Gallery</a>
                                        </li>
                                        {/* <li className="nav-item">
                                            <a className="page-scroll" href="#team">Team</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll" href="#blog">Blog</a>
                                        </li> */}
                                        <li className="nav-item">
                                            <a className="page-scroll" href="#contact">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="header_hero">
                <div className="single_hero bg_cover d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9 col-md-10">
                                <div className="hero_content text-center">
                                    <h2 className="hero_title hero_content-intro wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">White Shade Visa Consultancy</h2>
                                    <p className="wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.5s">We are the best study visa consultants for students who want to study abroad. The top and best
                                        study visa consultants in Punjab for UK, Canada, USA and Europe. We specialize in different aspects
                                        of International Education.</p>
                                <a href="#features" className="main-btn wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.8s">Explore</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    </section>
  );
}

export default Header;
