import React from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

function Contact() {
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_n1gz1fo', 'template_bradxja', e.target, 'Ism_XUQYIYkkcwVEy')
            .then((result) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Message Sent',
                    text: 'Message sent successfully!',
                });
            }, (error) => {
                console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Failed to send the message, please try again.',
                });
            });

        e.target.reset();
    };

    return (
        <section id="contact" className="contact_area bg_cover pt-120 pb-130" style={{ backgroundImage: "url(assets/images/contact_bg.jpg)" }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section_title section_title_2 text-center pb-25">
                            <h4 className="title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">Let's Get In Touch!</h4>
                            <p className="wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.4s">Have Any Question? Feel Free To Contact With Us.</p>
                        </div>
                    </div>
                </div>

                <form id="contact-form" onSubmit={sendEmail} className="wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.4s">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="single_form">
                                <input type="text" placeholder="Name" name="name" id="name" required />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="single_form">
                                <input type="email" placeholder="Email" name="email" id="email" required />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="single_form">
                                <input type="text" placeholder="Phone Number" name="number" id="number" required />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="single_form">
                                <input type="text" placeholder="Subject" name="subject" id="subject" required />
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="single_form">
                                <textarea placeholder="Message" name="message" id="message" required></textarea>
                            </div>
                        </div>

                        <p className="form-message"></p>

                        <div className="col-lg-12">
                            <div className="single_form text-center">
                                <button className="main-btn" type="submit">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
}

export default Contact;
