import React from 'react';

function VisaServices() {

    return (

        <section id="visas" className="features_area pt-120 pb-100 visas-page">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="section_title text-center">
                        <h4 className="title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">Visa Services</h4>
                    </div>
                </div>
            </div>
                <div className="row">

                    <div className="col-lg-3 col-md-6">
                        <div className="single_features text-center mt-60 wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">
                            <div className="mb-2">
                                <img className="visa-logo" alt="Study Visa" src={require('./../assets/images/study1.png')} />
                            </div>
                            <h4 className="features_title">Study Visa</h4>
                            <p>Our Visa Counselling Service is a comprehensive method which enables you to prepare your
                                visa application according to your profile.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="single_features text-center mt-60 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.3s">
                            <div className="mb-2">
                                <img className="visa-logo" alt="Visitor Visa" src={require('./../assets/images/visitor.png')} />
                            </div>
                            <h4 className="features_title">Visitor Visa</h4>
                            <p>We guide to you how to create a best profile of yourself which helps to grant your visa.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="single_features text-center mt-60 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.4s">
                            <div className="mb-2">
                                <img className="visa-logo" alt="Supouse Visa" src={require('./../assets/images/supouse.png')} />
                            </div>
                            <h4 className="features_title">Supouse Visa</h4>
                            <p>White Shade Visa Consultancy is one of the few visa consultancy services that promise to
                                approve your visa.</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6">
                        <div className="single_features text-center mt-60 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.2s">
                            <div className="mb-2">
                                <img className="visa-logo" alt="Tourist Visa" src={require('./../assets/images/tourist1.png')} />
                            </div>
                            <h4 className="features_title">Tourist Visa</h4>
                            <p>We guide to you how to create a best profile of yourself which helps to grant your visa.</p>
                        </div>
                    </div>

                </div>
        </div>
    </section>
    );
};

export default VisaServices;
