import React from 'react';

function TestimonialHeading() {
    return (
        <section id="portfolio" className="portfolio_area pt-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section_title text-center pb-60">
                            <h4 className="title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">Success Stories</h4>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TestimonialHeading;
