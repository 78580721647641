import React from 'react';

import blog1 from '../assets/images/blog-1.jpg';
import blog2 from '../assets/images/visitor-visa-blog.png';
import blog3 from '../assets/images/uk-study-permit.png';



function Blog() {
    return (
        <section id="blog" className="blog_area pt-120 pb-130">
             <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="section_title text-center pb-25">
                            <h4 className="title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">Recent Blog</h4>
                            <p className="wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.4s"></p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="single_blog mt-30 wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">
                            <div className="blog_image">
                                <img src={blog1} alt="blog" />
                            </div>
                            <div className="blog_content">
                                <h3 className="blog_title"><a href="#0">Empowering Your Global Journey with Expert Visa Guidance</a></h3>
                                <p> We specialize in providing up-to-date, reliable information on visas and immigration. Whether you're planning to visit, study, or settle abroad, our blog offers comprehensive insights, tips, and expert advice to help you navigate the complex world of international travel and immigration. Stay informed and confident as you embark on your journey with our tailored content, designed to meet all your visa-related needs.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="single_blog blog_2 d-sm-flex mt-30 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.2s">
                            <div className="blog_image">
                                <img src={blog2} alt="blog" />
                            </div>
                            <div className="blog_content media-body">
                                <h3 className="blog_title"><a href="https://www.youtube.com/watch?v=-jRd32nC15U&t=7s">Visitor Visa V/s Super Visa </a></h3>
                                <p>Discover the key differences and similarities between a visitor visa and a super visa, helping you choose the right option for your travel needs.</p>
                                {/* <a href="#0" className="more">Read More</a> */}
                            </div>
                        </div>

                        <div className="single_blog blog_2 d-sm-flex mt-30 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.3s">
                            <div className="blog_image">
                                <img src={blog3} alt="blog" />
                            </div>
                            <div className="blog_content media-body">
                                <h3 className="blog_title"><a href="https://www.youtube.com/watch?v=I04rwPjCIG0">UK Student Study Permit</a></h3>
                                <p>Learn everything you need to know about obtaining a UK student study permit, from application tips to visa requirements.</p>
                                {/* <a href="#0" className="more">Read More</a> */}
                            </div>
                        </div>

                        {/* <div className="single_blog blog_2 d-sm-flex mt-30 wow fadeInUp" data-wow-duration="1.3s"
                            data-wow-delay="0.4s">
                            <div className="blog_image">
                                <img src={blog4} alt="blog" />
                            </div>
                            <div className="blog_content media-body">
                                <h3 className="blog_title"><a href="#0">Lorem ipsum dolor sit amet, consetetur sadipscing .</a></h3>
                                <p>Lorem ipsum dolor sit consetetur sadipscing elitr, sed diam.</p>
                                <a href="#0" className="more">Read More</a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div> 
        </section>
    );
}

export default Blog;
