import React from 'react';

import success1 from '../assets/success/success1.jpg';
import success3 from '../assets/success/success3.jpg';
import success2 from '../assets/success/success2.jpg';
import success4 from '../assets/success/success4.jpg';
import success5 from '../assets/success/success5.jpg';
import success6 from '../assets/success/success6.jpg';


import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

import '../../src/slider.css';
import '../../src/App.css';

function Testimonial() {

    const AutoplaySlider = withAutoplay(AwesomeSlider);

    const bgImg = {
        backgroundImage: `url(${require('./../assets/images/wall.jpg')})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    };

    return (
        <section id="testimonial" className="testimonial_area pt-130 bg_cover" style={bgImg}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="testimonial_active_wrapper">
                            <AutoplaySlider play={true} interval={3000} organicArrows={false} bullets={false} >
                                <div className="single_testimonial text-center">
                                    <img src={success1} alt="author" className='testimonial-image'/>
                                    
                                </div>
                                <div className="single_testimonial text-center">
                                    <img src={success3} alt="author" className='testimonial-image'/>
                                    
                                </div>
                                <div className="single_testimonial text-center">
                                    <img src={success5} alt="author" className='testimonial-image'/>
                                    
                                </div>
                                <div className="single_testimonial text-center">
                                    <img src={success6} alt="author" className='testimonial-image'/>
                                    
                                </div>
                                <div className="single_testimonial text-center">
                                    <img src={success2} alt="author" className='testimonial-image'/>
                                    
                                </div>
                                <div className="single_testimonial text-center">
                                    <img src={success4} alt="author" className='testimonial-image'/>
                                    
                                </div>
                            </AutoplaySlider >
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;
